// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contest-rules-tsx": () => import("./../../../src/pages/contest_rules.tsx" /* webpackChunkName: "component---src-pages-contest-rules-tsx" */),
  "component---src-pages-distances-tsx": () => import("./../../../src/pages/distances.tsx" /* webpackChunkName: "component---src-pages-distances-tsx" */),
  "component---src-pages-goodfoot-tsx": () => import("./../../../src/pages/goodfoot.tsx" /* webpackChunkName: "component---src-pages-goodfoot-tsx" */),
  "component---src-pages-impress-tsx": () => import("./../../../src/pages/impress.tsx" /* webpackChunkName: "component---src-pages-impress-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ofm-tsx": () => import("./../../../src/pages/ofm.tsx" /* webpackChunkName: "component---src-pages-ofm-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-templates-blogpost-template-tsx": () => import("./../../../src/templates/blogpost.template.tsx" /* webpackChunkName: "component---src-templates-blogpost-template-tsx" */),
  "component---src-templates-gallery-template-tsx": () => import("./../../../src/templates/gallery.template.tsx" /* webpackChunkName: "component---src-templates-gallery-template-tsx" */)
}

